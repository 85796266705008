<template>
  <b-modal
    id="arquivo-view"
    ref="arquivo"
    hide-footer
    hide-header
    size="xl"
    class="file-modal m-0"
    content-class="modal-arquivo"
    data-backdrop="static"
    @hide="fechar()"
  >
    <div style="height: 90%;">
      <b-button
        v-if="!temCabecalhoCustom"
        class="close close-file"
        variant="none"
        @click.stop="fechar()"
      >
        <span aria-hidden="true">&times;</span>
      </b-button>
      <div :class="{'d-inline':!isHtml}">
        <div
          class="mx-5 my-2"
          :class="{'image-wrapper':isImage && !isVideo, 'pdf-wrapper': !isImage && !isVideo, 'video-wrapper': isVideo && !isImage}"
        >
          <slot
            v-if="isHtml"
            name="cabecalho-html"
          />

          <slot
            v-if="temCabecalhoCustom"
            name="cabecalho-custom"
          />

          <embed
            v-if="isImage && !isVideo && !isHtml"
            style="max-width:100%; max-height:90vh"
            :class="{'image-download': download}"
            :src="url"
          >
          <div
            v-else-if="isHtml"
            class="bg-white p-2"
            v-html="url"
          />
          <div
            v-else-if="arquivoSemVisualizacao || videoNaoSuportado"
            class="sem-visualizacao"
            :class="{'image-download': download}"
          >
            <div class="mensagem-visualizacao">
              <span>Clique em "Fazer download" para baixar o arquivo!</span>
            </div>
          </div>
          <iframe
            v-else-if="!isImage && !isVideo"
            :src="pdfUrl"
            frameborder="0"
            height="100%"
            width="100%"
          />
          <video
            v-else-if="!isImage && isVideo"
            width="100%"
            height="100%"
            controls
          >
            <source
              :src="url"
            >
          </video>
          <!-- sandbox="allow-top-navigation" -->
          <div :class="{'imprimir':isHtml, 'fazer-download':!isHtml, 'mt-1':isImage}">
            <!-- <span v-if="observacao !== ''">
              <strong>Observação:&nbsp;</strong>{{ observacao }}
            </span>
            <br> -->
            <div
              :class="{'mt-0':isHtml, 'mt-1':!isHtml}"
            >
              <font-awesome-icon
                :icon="faDownload"
                class="mr-2"
              />
              <span>
                <a
                  v-if="!isHtml"
                  :href="url"
                  :download="url"
                  target="_blank"
                  @click.stop
                >
                  Fazer download
                </a>
                <a
                  v-else
                  @click.stop="imprimirHtml"
                >
                  Imprimir
                </a>
              </span>
            </div>
          </div>
          <slot
            v-if="isHtml"
            name="rodape-html"
          />
        </div>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss">
#arquivo-view .modal-xl {
  height: calc(100vh - 6%);
  width: calc(95vw);
}

.modal-arquivo {
  background-color: inherit;
  height: 100%;

  .pdf-wrapper {
    position: relative;
    vertical-align: middle;
    height: 90%;
    & .close-file {
      margin-top: 0 !important;
    }
  }

  .image-wrapper {
    height: 90%;
    vertical-align: middle;
  }

  .video-wrapper {
    height: 90%;
    max-height: 85vh !important;
  }

  .close-file {
    background: transparent;
    color: #fff !important;
    font-size: 3rem;
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    font-weight: bold;
    border-radius: 80px;
    background-color: black;
    width: 45px;
    opacity: 0.7;
  }

  .cabecalho {
    border-bottom: 1px solid rgba(34, 41, 47, 0.05);
  }

  .imprimir {
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;
    color: #fff;
    font-size: 15px;
    text-align: center;
    width: 10rem;
    border-radius: 4px;
    background-color: rgb(0 0 0 / 60%);
    padding: 0.3rem;
    margin: auto;
    margin-top: 0.5rem;
    font-size: 12.6px;

    & a {
      text-decoration: underline;
      color: #fff;
      cursor: pointer;
    }
  }

  .fazer-download {
    color: #fff;
    font-size: 15px;
    text-align: center;
    width: 80%;
    border-radius: 4px;
    background-color: rgb(0 0 0 / 60%);
    padding: 0.3rem;
    margin: auto;
    margin-top: 0.5rem;
    font-size: 14.5px;

    & a {
      text-decoration: underline;
      color: #fff;
      cursor: pointer;
    }
  }

  .image-download {
    max-height: 85vh !important;
  }
  .pdf-download {
    height: 55% !important;
  }
}

.sem-visualizacao{
  background-color: #ededed;
  height: 100%;

  & div{
    text-align: center;
    width: 100%;
    height: 100%;
    padding-top: calc(20%);
    & span{
      background-color: #4c494c;
      border-radius: 12px;
      color: #fff;
      padding: 20px;
      text-align: center;
      box-shadow: 0px 10px 12px 5px rgb(0 0 0 / 20%);
      font-size: 19px;
      line-height: 27px;
    }
  }
}
</style>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faDownload,
} from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'VisualizarArquivo',
  components: {
    FontAwesomeIcon,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    observacao: {
      type: String,
      default: '',
    },
    download: {
      type: Boolean,
      default: false,
    },
    temCabecalhoCustom: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      faDownload,
      arquivoSemVisualizacao: false,
      videoNaoSuportado: false,
    }
  },
  computed: {
    pdfUrl() {
      if (this.arquivoSemVisualizacao) {
        return ''
      }
      this.verificaURL()
      return `${this.url}`
    },
    isImage() {
      if ((this.url.toLowerCase().includes('.png') || this.url.toLowerCase().includes('.jpg')) && !this.isHtml) {
        return true
      }
      return false
    },
    isVideo() {
      if (this.url.toLowerCase().includes('.mp4') || this.url.toLowerCase().includes('.webm') || this.url.toLowerCase().includes('.wmv')) {
        return true
      }
      return false
    },
    isHtml() {
      if (this.url.toLowerCase().includes('</strong>') || this.url.toLowerCase().includes('</html>')
        || this.url.toLowerCase().includes('</div>') || this.url.toLowerCase().includes('</head>')
        || this.url.toLowerCase().includes('</body>')) {
        return true
      }
      return false
    },
  },
  created() {
    this.$nextTick(() => {
      this.arquivoSemVisualizacao = this.url.toLowerCase().includes('.zip') || this.url.toLowerCase().includes('.rar')
      this.videoNaoSuportado = this.url.toLowerCase().includes('.wmv')
      this.show()
    })
  },
  methods: {
    show() {
      this.$refs.arquivo.show()
    },
    fechar() {
      this.$refs.arquivo.hide()
      this.$emit('hidden')
    },
    imprimirHtml() {
      const telaImpressao = window.open('about:blank')
      const style = document.createElement('style')
      style.innerText = '#conteudo-impressao { display:none } @media print { #conteudo-impressao { display: block !important; }}'

      if (this.url.indexOf('<html')) {
        telaImpressao.document.write(this.url)
        const body = telaImpressao.document.getElementsByTagName('body')[0]
        body.id = 'conteudo-impressao'
        body.appendChild(style)
      } else {
        telaImpressao.document.write(`<div id="conteudo-impressao">${this.url}</div>`)
        telaImpressao.document.head.appendChild(style)
      }
      telaImpressao.print()
      telaImpressao.window.close()
    },
    verificaURL() {
      fetch(this.url)
        .then(rota => {
          if (rota.status !== 200) {
            this.url = '<div>Documento não encontrado!</div>'
            this.isHtml()
          }
        })
    },
  },
}
</script>
